import axios from 'axios';
import QS from 'qs';
import { Message } from 'element-ui';
import router from '@/router';
// import store from '../store/index';
// import router from '@/router';

// axios.defaults.baseURL = 'https://whgadmin.ltzsgl.com/prod-api/';
// axios.defaults.baseURL = 'https://admin.lbxwhg.cn/prod-api/';
// axios.defaults.baseURL = 'https://jnqwhgadmin.ltzsgl.com/prod-api/';
axios.defaults.baseURL = 'https://byxadmin.ltzsgl.com/prod-api/';
// axios.defaults.baseURL = 'https://ltxwhgadmin.ltzsgl.com/prod-api/';
// axios.defaults.baseURL = 'https://sqxwhgadmin.ltzsgl.com/prod-api/';
// axios.defaults.baseURL = 'https://dgxwhgadmin.ltzsgl.com/prod-api/';
// axios.defaults.baseURL = 'https://jlxwhgadmin.ltzsgl.com/prod-api/';
// axios.defaults.baseURL = 'https://dbxwhgadmin.ltzsgl.com/prod-api/';
// axios.defaults.baseURL = 'http://localhost:8080/'
// axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://whg.admin.ltzsgl.com/prod-api/' : 'http://localhost:8081/';
// 请求超时时间
axios.defaults.timeout = 100000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
    (config) => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        // console.log('config', config);

        if (config.token) {
            const token = localStorage.getItem('token');
            // const token = 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImI1ODQ5ZmQyLTdiZTMtNGVlNi04MWQ4LWZmNWQ2ZTE3MTkyMCJ9.uScGGZcKUZl5N3lSRfjCSCq26F6Qih4GRRuKjh9uQnQT2yvcUuZNrw_Y8x00RWMw18_onGfWoohaBoumj-ZO4g';
            token && (config.headers.Authorization = 'Bearer ' + token);
        }
        return config;
    },
    (error) => {
        return Promise.error(error);
    },
);

// 响应拦截器
axios.interceptors.response.use(
    (response) => {
        switch (response.data.code) {
            case 200:
                return Promise.resolve(response);
            // 401: 未登录
            // 未登录则跳转登录页面，并携带当前页面的路径
            // 在登录成功后返回当前页面，这一步需要在登录页操作。
            case 401:
                Message.error('您未登录或登录已过期，请重新登录！');
                setTimeout(() => {
					// localStorage.clear();
					localStorage.removeItem('token');
					if(router.currentRoute.path=='/'){
						window.location.reload()
					} else {
						router.replace({
						    path: '/',
						});
					}
                });
                break;
            // 403 token过期
            // 登录过期对用户进行提示
            // 清除本地token和清空vuex中token对象
            // 跳转登录页面
            case 403:
                Message.error('登录过期，请重新登录');
                // 清除token
                // localStorage.removeItem('token');
                // store.commit('loginSuccess', null);
                // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                setTimeout(() => {
                    localStorage.clear();
                    router.replace({
                        path: '/',
                    });
                }, 2000);
                break;
            // 404请求不存在
            case 404:
                Message.error('网络请求不存在');
				return Promise.reject(response)
            // 500服务器错误
            case 500:
                Message.error(response.data.msg);
				return Promise.reject(response)
            // 其他错误，直接抛出错误提示
            default:
                if (response.data.communityCount != undefined) break;

                Message.error('系统错误，请联系管理员');
				return Promise.reject(response)
        }
        return Promise.resolve(response);
    },
    // 服务器状态码不是200的情况
    (error) => {
        if (error.response.status) {
            switch (error.response.status) {
                case 401:
					localStorage.removeItem('token');
                    this.$message.error('登录过期，请重新登录');
                    break;
                case 403:
                    this.$message.error('拒绝访问');
                    break;
                case 404:
                    this.$message.error('请求错误,未找到该资源');
                    break;
                case 500:
                    this.$message.error('服务器端出错');
                    break;
            }

            return Promise.reject(error.response);
        }
    },
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(data = {}) {
    // data = checkPageNum(data);
    return new Promise((resolve, reject) => {
        axios
            .get(data.url, {
                params: data.data,
                token: data.token || false,
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(data = {}) {
    // data = checkPageNum(data);
    return new Promise((resolve, reject) => {
        axios
            .post(data.url, QS.parse(data.data), data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}
/**
 * delete方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function _delete(data = {}) {
    // data = checkPageNum(data);
    return new Promise((resolve, reject) => {
        axios
            .delete(data.url, {
                params: data.data,
                token: data.token || false,
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}

/**
 * put方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(data = {}) {
    // data = checkPageNum(data);
    return new Promise((resolve, reject) => {
        axios
            .put(data.url, QS.parse(data.data), data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err.data);
            });
    });
}

//判断接口返回状态
export function checkStatus(code) {
    // console.log(code);
    switch (code) {
        case 200:
            return true;
        case 401:
			localStorage.removeItem('token');
            this.$message.error('登录过期，请重新登录');
            return false;
        case 403:
            this.$message.error('登录过期，请重新登录');
            return false;
        case 404:
            this.$message.error('网络请求不存在');
            return false;
        case 500:
            this.$message.error('系统错误，请联系管理员');
            return false;
    }
}

//判断是否有有pageNum这个字段
export function checkPageNum(data) {
    if (data.data) {
        if (data.data.pageNum) {
            data.data.libraryId = localStorage.getItem('id');
            return data;
        } else {
            return data;
        }
    } else {
        return data;
    }
}
